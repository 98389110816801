import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { STUDIO_TOOLS } from '../../components/studio/constants';
import { client } from '../../graphql/Server';
import { POST_PRINTFUL_API } from '../../graphql/printful';

const initialState = {
  modifyProductInfo: {},
  isEdit: false,
  canvasesFiles: [],
  selectedShape: {},
  mockupMessage: '',
  mockups: [],
  selectedMockup: {},
  isGeneratingMockups: false,
  mockupsGenerateError: null,
  productId: '',
  hasError: null,
  isLoading: false,
  isOpenStudio: false,
  isDesignOrMockup: 'design',
  product: {},
  tools: STUDIO_TOOLS,
  activeTool: 'Product',
  variants: [],
  canvases: {},
  shapes: {},
  views: [],
  selectedVariant: {},
  selectedView: {},
  colors: [],
  sizes: [],
  selectedCanvases: [],
};

const slice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    // CANVASES REDUCERS ----------------------------
    setCanvas(state, action) {
      state.canvases = action.payload;
    },

    clearCanvases(state) {
      state.canvases = {};
    },

    addShape(state, action) {
      const { canvasId, shape } = action.payload;
      // Ensure that the canvasId exists in the state.shapes
      state.shapes = {
        ...state.shapes,
        [canvasId]: [...(state.shapes[canvasId] || []), shape],
      };
    },

    editShape(state, action) {
      const { canvasId, shapeId, property, value } = action.payload;
      const newShapes = state.shapes[canvasId].map((shape) => {
        return shape.id === shapeId ? { ...shape, [property]: value } : shape;
      });
      state.shapes = {
        ...state.shapes,
        [canvasId]: newShapes,
      };
    },

    removeShape(state, action) {
      const { canvasId, shapeId } = action.payload;
      const newShapes = state.shapes[canvasId].filter((shape) => shape.id !== shapeId);
      state.shapes = {
        ...state.shapes,
        [canvasId]: newShapes,
      };
    },

    setSelectedShape(state, action) {
      const { canvasId, shapeId } = action.payload;
      state.selectedView = state.views.find((view) => view.type === canvasId);
      state.selectedShape = action.payload;
    },

    setCanvasesFiles(state, action) {
      state.canvasesFiles = action.payload;
    },

    toggleCanvases(state, action) {
      const exists = state.selectedCanvases.includes(action.payload);
      state.selectedCanvases = exists
        ? state.selectedCanvases.filter((canvas) => canvas !== action.payload)
        : [...state.selectedCanvases, action.payload];
    },
    // CANVASES REDUCERS ----------------------------

    // MOCKUPS REDUCERS -----------------------------
    hasMockupsGenerateError(state, action) {
      state.isGeneratingMockups = false;
      state.mockupsGenerateError = action.payload;
    },
    setMockups(state, action) {
      state.isGeneratingMockups = false;
      state.mockups = action.payload;
      state.mockupMessage = '';
      state.mockupsGenerateError = null;
    },
    startToGenerateMockups(state) {
      state.isGeneratingMockups = true;
    },
    setMockupMessage(state, action) {
      state.mockupMessage = action.payload;
    },
    setSelectedMockup(state, action) {
      state.selectedMockup = action.payload;
    },
    // MOCKUPS REDUCERS -----------------------------

    hasError(state, action) {
      state.isLoading = false;
      state.hasError = action.payload;
    },
    setProductId(state, action) {
      state.productId = action.payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    openStudio(state, action) {
      state.isOpenStudio = true;
      state.isEdit = action.payload || false;
    },
    setModifyProductInfo(state, action) {
      state.modifyProductInfo = action.payload;
    },
    closeStudio(state) {
      state.productId = '';
      state.isOpenStudio = false;
      state.mockups = [];
      state.selectedMockup = {};
      state.isDesignOrMockup = 'design';
      state.selectedShape = {};
      state.modifyProductInfo = {};
      state.isEdit = false;
    },
    setDesignOrMockup(state, action) {
      state.isDesignOrMockup = action.payload;
    },
    setProduct(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },
    setVariants(state, action) {
      state.isLoading = false;
      state.variants = action.payload?.map((variant) => ({ ...variant, isSelected: false }));
    },
    setColors(state, action) {
      const colors = action.payload?.map((v) => ({ id: v.id, color: v.color_code, isSelected: false }));
      const uniqueColors = colors.filter((variant, idx, colors) => {
        return colors.findIndex((color) => color.color === variant.color) === idx;
      });
      state.colors = uniqueColors;
    },
    setActiveColor(state, action) {
      state.colors = state.colors?.map((color) => {
        return color.color === action.payload ? { ...color, isSelected: !color.isSelected } : color;
      });
    },
    setActiveSize(state, action) {
      state.sizes = state.sizes?.map((size) => {
        return size.size === action.payload ? { ...size, isSelected: !size.isSelected } : size;
      });
    },
    setSizes(state, action) {
      const sizes = action.payload?.map((v) => ({ id: v.id, size: v.size, isSelected: true }));
      const uniqueSizes = sizes.filter((variant, idx, sizes) => {
        return sizes.findIndex((size) => size.size === variant.size) === idx;
      });
      state.sizes = uniqueSizes;
    },
    setVariant(state, action) {
      state.selectedVariant = action.payload;
    },
    setViews(state, action) {
      state.isLoading = false;
      state.views = action.payload;
      const defaultShapes = action.payload.reduce((acc, view) => {
        acc[view.type] = [
          {
            id: Date.now().toString(),
            type: 'image',
            src: view.url || view.preview_url || view.thumbnail_url,
            width: 240,
            height: 320,
            x: 0,
            y: 0,
          },
        ];
        return acc;
      }, {});
      console.log({ defaultShapes });
      state.shapes = { ...defaultShapes };
    },
    setSelectedView(state, action) {
      state.selectedView = action.payload;
    },
    setActiveTool(state, action) {
      state.activeTool = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  toggleCanvases,
  setSelectedShape,
  removeShape,
  editShape,
  setSelectedMockup,
  clearCanvases,
  addShape,
  setCanvas,
  setActiveSize,
  setColors,
  setSizes,
  setDesignOrMockup,
  openStudio,
  setProduct,
  setSelectedView,
  setVariant,
  setVariants,
  setViews,
  setHasError,
  startLoading,
  closeStudio,
  setProductId,
  setActiveTool,
  setActiveColor,
} = slice.actions;

export function setModifyProduct(args) {
  return async () => {
    console.log({ args });
    dispatch(slice.actions.setModifyProductInfo(args?.result?.sync_product));
    dispatch(slice.actions.setProduct(args?.result?.sync_variants?.[0]?.product));
    dispatch(slice.actions.setVariants(args?.result?.sync_variants));
    dispatch(slice.actions.setViews(args?.result?.sync_variants?.[0]?.files));
    dispatch(slice.actions.setSelectedView(args?.result?.sync_variants?.[0]?.files?.[0]));
    dispatch(slice.actions.setVariant(args?.result?.sync_variants?.[0]));
    dispatch(slice.actions.openStudio(true));
  };
}

export function getStudioProductById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setProductId(id));
    try {
      const res = await fetch(`https://api.printful.com/products/${id}`);
      const jsonRes = await res.json();
      console.log({ jsonRes });
      dispatch(slice.actions.setProduct(jsonRes?.result?.product));
      dispatch(slice.actions.setVariants(jsonRes?.result?.variants));
      dispatch(slice.actions.setColors(jsonRes?.result?.variants));
      dispatch(slice.actions.setSizes(jsonRes?.result?.variants));
      dispatch(slice.actions.setViews(jsonRes?.result?.product?.files));
      dispatch(slice.actions.setSelectedView(jsonRes?.result?.product?.files?.[0]));
      dispatch(slice.actions.setVariant(jsonRes?.result?.variants?.[0]));
      dispatch(slice.actions.openStudio());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function generateProductMockups({ canvases, productId, variantsIds }) {
  dispatch(slice.actions.startToGenerateMockups());
  return async () => {
    try {
      const blobPromises = Object.entries(canvases).map(async ([key, canvas]) => {
        const blob = await canvas.toBlob();
        return { key, blob };
      });
      const resolvedBlobs = await Promise.all(blobPromises);
      console.log({ resolvedBlobs });

      const imagePaths = resolvedBlobs.map(async ({ key, blob }) => {
        const formData = new FormData();
        formData.append('media', blob, `${key}.png`);
        formData.append('key', key);
        const response = await fetch('https://airomnishopback.devssh.xyz/upload-media', {
          method: 'POST',
          body: formData,
        });
        const jsonResponse = await response.json();
        return { key, imagePath: jsonResponse };
      });

      dispatch(slice.actions.setMockupMessage("Generating you'r designs..."));

      const resolvedPaths = await Promise.all(imagePaths);
      console.log({ resolvedPaths }); // Final paths ko check karne ke liye

      const dynamicFiles = resolvedPaths.map(({ key, imagePath }) => ({
        type: key,
        image_url: `https://airomnishopback.devssh.xyz${imagePath.data[0].name}`,
        position: {
          area_width: 1800,
          area_height: 2400,
          width: 1800,
          height: 1800,
          top: 300,
          left: 0,
        },
      }));

      dispatch(slice.actions.setCanvasesFiles(dynamicFiles));

      console.log({ dynamicFiles });

      dispatch(slice.actions.setMockupMessage('Generating prinful tasks...'));

      const processTasks = await CreatePfTask({ productId, variantsIds, files: dynamicFiles });
      console.log({ processTasks });

      dispatch(slice.actions.setMockupMessage('Getting prinful tasks...'));

      const pfTask = await GetPfTaskByTaskByTaskKey(processTasks.result.task_key);
      console.log({ pfTask });
      dispatch(slice.actions.setMockups(pfTask?.result?.mockups));
      dispatch(slice.actions.setSelectedMockup(pfTask?.result?.mockups?.[0]));
    } catch (error) {
      console.error('Error generating product mockups:', error);
      dispatch(slice.actions.hasMockupsGenerateError(error.message));
    }
  };
}

// Example usage of the _create_pf_task function
export async function CreatePfTask(args) {
  console.log({ args, pfToken: localStorage.getItem('printfulToken') });
  const { productId = 0, variantsIds = [], files = [] } = args || {};
  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', localStorage.getItem('accessToken'));
    const graphql = JSON.stringify({
      query:
        'mutation RunPrintfulPostRequest($url: String!, $body: String!) {\r\n  runPrintfulPostRequest(url: $url, body: $body)\r\n}\r\n',
      variables: {
        url: `https://api.printful.com/mockup-generator/create-task/${productId}`,
        body: JSON.stringify({ variant_ids: variantsIds, files }),
      },
    });

    const requestOptions = {
      method: 'POST',
      headers,
      body: graphql,
      redirect: 'follow',
    };

    const response = await fetch('https://airomnishopback.devssh.xyz/graphql', requestOptions);

    const JsonRes = await response.json();

    return JSON.parse(JsonRes.data.runPrintfulPostRequest);
  } catch (error) {
    console.log('Something went wrong when task is run', error);
  }
}

export async function GetPfTaskByTaskByTaskKey(taskKey) {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', localStorage.getItem('accessToken'));
        const graphql = JSON.stringify({
          query: 'mutation RunPrintfulGetRequest($url: String!) {\r\n  runPrintfulGetRequest(url: $url)\r\n}',
          variables: { url: `https://api.printful.com/mockup-generator/task?task_key=${taskKey}` },
        });

        const requestOptions = {
          method: 'POST',
          headers,
          body: graphql,
          redirect: 'follow',
        };

        const response = await fetch('https://airomnishopback.devssh.xyz/graphql', requestOptions);

        const JsonRes = await response.json();
        const res = JSON.parse(JsonRes.data.runPrintfulGetRequest);
        console.log('JsonRes ==>', res);
        if (res.result.status === 'pending') {
          resolve(await GetPfTaskByTaskByTaskKey(res.result.task_key));
        } else if (res.result.status === 'completed') {
          resolve(res);
        } else {
          reject(new Error('Mockup generation error!'));
        }
      } catch (error) {
        console.log('something went wrong to get task', error);
        reject(error);
      }
    }, 1000);
  });
}
