
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, createHttpLink, from,split, HttpLink } from '@apollo/client';

import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
const httpLink = createHttpLink({
  uri:"https://airomnishopback.devssh.xyz/graphql"  ,
  
})
const wsLink = new WebSocketLink({
  uri: 'wss://airomnishopback.devssh.xyz/graphql',
  options: {
    reconnect: true
  }
})
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)



  export const client = new ApolloClient({
    link:  from([
      authLink.concat(splitLink),
    //   authLink.concat(uploadLink),
    ]),
    cache: new InMemoryCache(),
  });