import { lazy } from 'react';
import ReactDOM from 'react-dom';
// routes
import { ToastContainer } from 'react-toastify';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { useSelector } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';

const Studio = lazy(() => import('./components/studio'));
// ----------------------------------------------------------------------

export default function App() {
  const isOpen = useSelector((state) => state?.studio?.isOpenStudio || false);
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <Settings />
            <ScrollToTop />
            <Router />
            <ToastContainer autoClose={4000} />
            {isOpen && ReactDOM.createPortal(<Studio />, document.body)}
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
