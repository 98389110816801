import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { LOGIN_AS_USER } from '../../../graphql/Auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { Login } = useAuth();
  const navigate = useNavigate();
  const [loginAsUser] = useMutation(LOGIN_AS_USER);

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: 'superadmin@hnh.pk',
    password: '12345678',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    try {
      const response = await loginAsUser({
        variables: {
          email: data.email,
          password: data.password,
        },
      });
      console.log({ response });
      let errorMsg;
      if (response.data.loginAsUser.otpRequired) {
        return navigate(`/auth/verify?email=${data.email}`);
      }
      // eslint-disable-next-line default-case
      switch (response.data.loginAsUser.user.store.status) {
        case 'DEACTIVE':
          errorMsg = { message: 'Your account has been deactivated.' };
          throw errorMsg;

        case 'REJECTED':
          errorMsg = { message: 'Your account has been rejected.' };
          throw errorMsg;
        case 'EXPIRED':
          errorMsg = { message: 'Your account has been expired.' };
          throw errorMsg;
        case 'BANNED':
          errorMsg = { message: 'Your account has been banned.' };
          throw errorMsg;

        case 'PENDING':
          errorMsg = { message: 'Your account has not approved.' };
          throw errorMsg;
        case 'ACTIVE':
          Login(response.data.loginAsUser);
          break;
        case 'APPROVED':
          Login(response.data.loginAsUser);
          break;
      }
    } catch (error) {
      reset();
      console.error(error);

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <Stack direction="column" sx={{ my: 4 }}>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
        <Button fullWidth size="large" type="button" variant="text" onClick={() => navigate('/auth/register')}>
          Create Store
        </Button>
      </Stack>
    </FormProvider>
  );
}
