import { gql } from '@apollo/client';

export const LOGIN_AS_USER = gql`
  mutation LoginAsUser($email: String!, $password: String!) {
    loginAsUser(email: $email, password: $password) {
      token
      otpRequired
      user {
        dob
        email
        firstName
        emailVerifiedAt
        id
        lastName
        role
        store {
          id
          name
          status
        }
      }
    }
  }
`;

export const GET_ADMIN_INFO = gql`
  query Me {
    me {
      id
      role
      firstName
      lastName
      profilePic
      dob
      email
      emailVerifiedAt
      country
      countryCode
      phone
      phoneVerifiedAt
      createdAt
    }
  }
`;

export const sendOTPToEnableEmail2Factor = gql`
  mutation SendOTPToEnableEmail2Factor {
    sendOTPToEnableEmail2Factor
  }
`;

export const EnableEmail2factor = gql`
  mutation Mutation($otp: String!) {
    enableEmail2Factor(otp: $otp)
  }
`;

export const LoginWithOTP = gql`
  mutation LoginAsUserWithOtp($email: String!, $otp: String!) {
    loginAsUserWithOtp(email: $email, otp: $otp) {
      token
      user {
        id
        role
        firstName
        lastName
        profilePic
        dob
        email
        emailVerifiedAt
        country
        countryCode
        phone
        phoneVerifiedAt
        createdAt
        store {
          id
          name
          coverPic
          description
          Address {
            id
            street
            street2
            city
            state
            country
            zipCode
            countryCode
            phone
            personName
            lon
            lat
            createdAt
          }

          subscriptions {
            id
            isActive
            expiredAt
            Package {
              id
              name
              price
            }
          }
        }
      }
    }
  }
`;
