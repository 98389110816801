import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUBSCRIPTION, MARK_ALL_AS_READ, MARK_AS_READ } from "../../../graphql/Notifications";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [markAllNotificationAsRead] = useMutation(MARK_ALL_AS_READ, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
   
  
  });
  const [MARKASREAD] = useMutation(MARK_AS_READ, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
   
  
  });
  const { data: notifications, loading, error, subscribeToMore } = useQuery(GET_NOTIFICATIONS,{
    variables: { all: true},
  })
  // const [notifications, setNotifications] = useState(_notifications);
  const getUnreadNotifications = () => {
    let count = 0
    if (notifications?.getNotifications) {
      notifications?.getNotifications?.forEach(notification => {
        if (notification.isUnread === true) {
          // eslint-disable-next-line no-plusplus
          count++
        }
      })
      return count
    }
    return false
  }

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };



  useEffect(() => {

    const abc = subscribeToMore({
      document: GET_NOTIFICATIONS_SUBSCRIPTION,
      variables: {
        userId: user.id,
      },
      onError: (error) => {
        console.log("error", error)
      },
      updateQuery: (prev, { subscriptionData }) => {

        if (!subscriptionData.data) {
          return prev
        }

        const newFeedItem = subscriptionData.data.notificationReceived
        toast.info(newFeedItem.body);
        return { ...prev, getNotifications: [newFeedItem, ...prev.getNotifications] }
      }
    })

    return abc


  }, [user?.id, subscribeToMore])


  return (
    <>
      { }
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={notifications?.getNotifications && getUnreadNotifications()} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        {error && <p>Error: {error.message}</p>}
        {loading ? <CircularProgress /> :
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">Notifications</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  You have {notifications?.getNotifications && getUnreadNotifications()} unread messages
                </Typography>
              </Box>

              {getUnreadNotifications() > 0 && (
                <Tooltip title=" Mark all as read">
                  <IconButtonAnimate color="primary" onClick={() => markAllNotificationAsRead()}>
                    <Iconify icon="eva:done-all-fill" width={20} height={20} />
                  </IconButtonAnimate>
                </Tooltip>
              )}
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>


              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    New
                  </ListSubheader>
                }
              >
                {notifications?.getNotifications?.slice(0, 2).map((notification) => (
                  <NotificationItem markasread={()=>{
                    MARKASREAD({variables:{notificationId:notification.id}})
                  }} key={notification.id} notification={notification} />
                ))}
              </List>

              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    Before that
                  </ListSubheader>
                }
              >
                {notifications?.getNotifications?.slice(2, 5).map((notification) => (
                  <NotificationItem markasread={()=>{
                    MARKASREAD({variables:{notificationId:notification.id}})
                  }} key={notification.id} notification={notification} />
                ))}
              </List>
            </Scrollbar>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Box sx={{ p: 1 }}>
              <Button fullWidth disableRipple onClick={()=>navigate('/dashboard/notification/list')}>
                View All
              </Button>
            </Box>
          </>
        }
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    
  },
  ),
  markasread:PropTypes.func
};

function NotificationItem({ notification,markasread }) {
  const { avatar, title ,link } = renderContent(notification);
  const createdAt = new Date(notification?.createdAt)
  return (
    <Link onClick={()=>markasread()} style={{textDecoration:"none" }} to={link}>
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
    </Link>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.body)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      link: `/dashboard/orders/${notification.id}`,
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      link: `/dashboard/orders/${notification.id}`,
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      link: `/dashboard/chat/list`,
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'NEW_MESSAGE') {
    return {
      link: `/dashboard/chat/list`,
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.title} /> : null,
    title,
    link: `/`,
  };
}
