import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

// utils
import { useSearchParams } from 'react-router-dom';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { HOST_API } from '../config';

import { CREATE_SELLER } from '../graphql/Seller';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  Login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [RegisterSeller] = useMutation(CREATE_SELLER);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        let accessToken = params.get('accessToken');
        console.log(accessToken);
        if (accessToken) {
          accessToken = accessToken?.replace(/"/g, '');
        }

        if (!accessToken) {
          accessToken = window.localStorage.getItem('accessToken') || window.sessionStorage.getItem('accessToken');
        }

        console.log(accessToken);

        console.log(accessToken && isValidToken(accessToken));

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.post('https://airomnishopback.devssh.xyz/graphql', {
            query: `query Me {
              me {
                id
                role
                firstName
                lastName
                profilePic
                dob
                email
                emailVerifiedAt
                country
                countryCode
                phone
                phoneVerifiedAt
                createdAt

                store {
                  id
                  name
                  coverPic
                  description
                  Address {
                    id
                    
                    
                    country
                    zipCode
                    countryCode
                    phone
                    personName
                    lon
                    lat
                    createdAt
                  }
               
                    subscriptions {
                      id
                      isActive
                      expiredAt
                      Package {
                        id
                        name
                        price
                      }
                   
                  }
                }
              }
            }`,
          });
          console.log(response.data?.data?.me, 'refresh');
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: response.data?.data?.me,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const Login = async (data) => {
    const { token, user } = data;
    console.log({ afterUserLOgin: user });
    setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };
  const register = async (args) => {
    console.log('Registration start');
    await RegisterSeller({
      variables: { ...args },
    })
      .then((e) => {
        console.log(e);
      })
      .catch((e) => {
        console.error(e);
      });
    console.log('Registration end');

    // const response = await axios.post('/api/account/register', {
    //   email,
    //   password,
    //   firstName,
    //   lastName,
    // });
    // const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user,
    //   },
    // });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        Login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
