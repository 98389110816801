import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS =gql`query GetNotifications($all: Boolean) {
    getNotifications(all: $all) {
      id
      Sender {
        firstName
        lastName
        id
      }
      Receiver {
        id
        lastName
        firstName
      }
      createdAt
      isUnread
      readAt
      title
      type
      body
    }
  }`


export const GET_NOTIFICATIONS_SUBSCRIPTION = gql`subscription NotificationReceived($userId: String!) {
    notificationReceived(userId: $userId) {
      id
      body
      Sender {
        id
        firstName
        lastName
      }
      Receiver {
        id
        firstName
        lastName
      }
      createdAt
      isUnread
      readAt
      title
      type
    }
  }`

export const MARK_AS_READ =gql`mutation Mutation($notificationId: String!) {
  markNotificationAsRead(notificationId: $notificationId)
}`

export const MARK_ALL_AS_READ =gql`mutation Mutation {
  markAllNotificationAsRead
}`