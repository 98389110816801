import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  product: null,
  variants: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setPfProduct(state, action) {
      state.product = action.payload;
    },
    setPfVariants(state, action) {
      state.variants = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setPfProduct, setPfVariants } = slice.actions;
