import { Box, Checkbox, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import { setActiveSize } from '../../../redux/slices/studio';

export default function VariantsSizes() {
  const dispatch = useDispatch();
  const sizes = useSelector((state) => state.studio.sizes);
  // console.log({ sizes });

  return (
    <Box display="flex" gap={1} padding={2} flexWrap="wrap">
      {sizes?.map((size) => (
        <Box key={size.size} display="flex" alignItems="center">
          <Checkbox
            checked={size.isSelected}
            sx={{
              width: 30,
              height: 30,
              padding: 0,
              '& .MuiSvgIcon-root': { fontSize: 24 },
            }}
            onChange={() => dispatch(setActiveSize(size.size))}
          />
          <Typography variant="body2">{size.size}</Typography>
        </Box>
      ))}
    </Box>
  );
}
