import { Box, Button, Grid } from '@mui/material';
import { Circle, Square, Star } from '@mui/icons-material';

import { useDispatch, useSelector } from '../../../redux/store';
import { addShape } from '../../../redux/slices/studio';

export default function TextSection() {
  const dispatch = useDispatch();
  const canvasId = useSelector((state) => state.studio.selectedView.type);

  return (
    <Box padding={2} key={canvasId}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          dispatch(
            addShape({
              canvasId,
              shape: {
                id: Date.now().toString(),
                type: 'text',
                text: 'Some text here',
                x: 20,
                y: 20,
                fontSize: 20,
                width: 200,
                height: 10,
                fill: '',
                fontFamily: 'Calibri',
              },
            })
          );
        }}
      >
        Add Text
      </Button>

      <Box marginTop={4}>
        <Grid container spacing={2}>
          {[Circle, Square, Star].map((Shape, index) => (
            <Grid item xs={4} sm={3} md={2} key={index}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Shape style={{ fontSize: 60 }} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
