import SvgIconStyle from '../SvgIconStyle';
import ClipartSection from './components/ClipartSection';
import FillSection from './components/FillSection';
import HelpSection from './components/HelpSection';
import LayersSection from './components/LayersSection';
import PremiumSection from './components/PremiumSection';
import ProductSection from './components/ProductSection';
import QuickDesignSection from './components/QuickDesignSection';
import TextSection from './components/TextSection';
import UploadSection from './components/UploadSection';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 30, height: 30 }} />;
const getToolIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 25, height: 25 }} />;

export const STUDIO_ICONS = {
  X: getIcon('ic_x'),
  UNDO: getIcon('ic_undo'),
  REDO: getIcon('ic_redo'),
  CHEVRON_LEFT: getIcon('ic_chevron_left'),
};

export const STUDIO_TOOLS = [
  { name: 'Product', icon: getToolIcon('ic_shirt') },
  { name: 'Layers', icon: getToolIcon('ic_layers') },
  { name: 'Upload', icon: getToolIcon('ic_upload') },
  { name: 'Text', icon: getToolIcon('ic_type') },
  { name: 'Clipart', icon: getToolIcon('ic_smile') },
  { name: 'Quick Design', icon: getToolIcon('ic_sparkles') },
  { name: 'Premium', icon: getToolIcon('ic_imagePlus') },
  { name: 'Fill', icon: getToolIcon('ic_paint-bucket') },
  { name: 'Help', icon: getToolIcon('ic_badge-help') },
];

export const TOOLS_SECTIONS = {
  Product: ProductSection,
  Layers: LayersSection,
  Upload: UploadSection,
  Text: TextSection,
  Clipart: ClipartSection,
  'Quick Design': QuickDesignSection,
  Premium: PremiumSection,
  Fill: FillSection,
  Help: HelpSection,
};
