import { gql } from '@apollo/client';

export const CREATE_SELLER = gql`
  mutation RegisterAsSeller(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $dob: String!
    $country: String!
    $countryCode: String!
    $phone: String!
    $storeName: String!
  ) {
    registerAsSeller(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      dob: $dob
      country: $country
      countryCode: $countryCode
      phone: $phone
      storeName: $storeName
    ) {
      user {
        id
        firstName
        lastName
        email
        dob
        country
        countryCode
        phone
        store {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_SELLER = gql`
  mutation UpdateStore(
    $userId: String!
    $firstName: String!
    $lastName: String!
    $dob: String!
    $country: String!
    $countryCode: String!
    $phone: String!
    $street: String!
    $city: String!
    $state: String!
    $zipCode: String!
    $lon: String!
    $lat: String!
    $storeDescription: String
    $profilePic: String
    $coverPic: String
    $street2: String
  ) {
    updateStore(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      country: $country
      countryCode: $countryCode
      phone: $phone
      street: $street
      city: $city
      state: $state
      zipCode: $zipCode
      lon: $lon
      lat: $lat
      storeDescription: $storeDescription
      profilePic: $profilePic
      coverPic: $coverPic
      street2: $street2
    ) {
      id
      firstName
      lastName
    }
  }
`;

export const DELETE_SELLER = gql`
  mutation DeleteAdmin($adminId: Int!) {
    deleteAdmin(adminId: $adminId)
  }
`;

export const GET_SELLER_BY_ID = gql`
  query AdminById($adminId: Int!) {
    adminById(adminId: $adminId) {
      id
      firstName
      lastName
      dob
      profilePic
      email
      country
      countryCode
      phone
      isSuperAdmin
    }
  }
`;

export const GET_ALL_SELLER = gql`
  query Sellers {
    sellers {
      id
      role
      firstName
      lastName
      profilePic
      dob
      email
      emailVerifiedAt
      country
      countryCode
      phone
      phoneVerifiedAt
      createdAt
    }
  }
`;
