import { Accordion, AccordionDetails, AccordionSummary, Box, List, Typography } from '@mui/material';
import * as Condition from 'react-if';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { useDispatch, useSelector } from '../../../redux/store';
import { setCanvas } from '../../../redux/slices/studio';
import { EditShapePanel } from './EditShapePanel';

export default function LayersSection() {
  const shapes = useSelector((state) => state.studio.shapes);
  const view = useSelector((state) => state.studio.selectedView);

  return (
    <List sx={{ height: 'calc(100vh - 160px)', overflowY: 'auto', overflowX: 'hidden' }}>
      {shapes[view.type]?.map((shape) => (
        <EditShapePanel shape={shape} />
      ))}
    </List>
  );
}
