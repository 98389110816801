import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import { setActiveColor } from '../../../redux/slices/studio';
import VariantsColors from './VariantsColors';
import VariantsSizes from './VariantSizes';

export default function ProductSection() {
  const dispatch = useDispatch();
  const colors = useSelector((state) => state.studio.colors);
  // console.log({ colors });
  return (
    <Box padding={2}>
      <Typography variant="h6" marginBottom={1}>
        Colors
      </Typography>
      <VariantsColors />

      <Typography variant="h6" marginTop={4} marginBottom={1}>
        Sizes
      </Typography>
      <VariantsSizes />
    </Box>
  );
}
